import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    WORDPRESS: "WordPress",
    REACT: "React",
    ANIMATION: "Animation",
    GRAPHICDESIGN: "Graphic Design",
    // VIMEO: "Vimeo Videos",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "Detailed Project 1",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I completely redesigned and developed this website. This client operates a dental laboratory providing techniques, products and services to meet all dentists restorative needs",
        client: "Klausz Dental Laboratories Ltd.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dental Laboratories",
        // date: "July 16, 2019",
        url: {
          name: "klauszdentallab.com",
          link: "https://www.klauszdentallab.com",
        },
        sliderImages: [
          // "images/projects/project-1.jpg",
          // "images/projects/project-2.png",
        ],
      },
      thumbImage: "images/projects/project-17.jpg", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "React Project 1",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "School Project: Designed and developed a Single Page Application (SPA) for a Relaxation SPA",
        // client: "Ruby Clinton",
        technologies: "React, React-Router, Styled Components, UseState, Bootstrap, HTML, CSS",
        industry: "Health, SPA",
        // date: "July 16, 2019",
        url: {
          name: "wellness-spa.sobadiaportfolio.com",
          link: "https://wellness-spa.sobadiaportfolio.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/react-project-1.jpg",

      categories: [filters.REACT],
    },
    {
      title: "SVG Animation Project 1",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project involving animating an SVG illustration (Martini) using GSAP (Javascript Framework for Amimation) Javascript, HTML, and CSS",
        client: "Personal Project",
        technologies: "HTML, CSS3, Javascript, GSAP,  SVG",
        industry: "SVG Animation",
        // date: "July 16, 2019",
        url: {
          name: "https://sobadiaportfolio.com/martini/",
          link: "https://sobadiaportfolio.com/martini/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/svg-project-1.png",

      categories: [filters.ANIMATION],
    },
    {
      title: "Graphic Design Project 1",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Created various sized full-colour ads for Quebec, Ontario, and Western publications, including: Remax-Québec, Maisons à vendre, Home Décor & Renovations, and Commercial Investor",
        client: "Wall2wall Media (currently NextHome Media)",
        technologies: "Adobe Illustrator, Adobe Photoshop, Adobe Indesign",
        industry: "Publishing",
        date: "July 16, 2019",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-1.jpg",

      categories: [filters.GRAPHICDESIGN],
    },

    {
      title: "Detailed Project 2",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this bilingual website for whom the client is a dental surgeon who operates in Montreal",
        client: "Dr. Michael Youssef - Dental Surgeon",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "dentistinmontreal.com",
          link: "https://dentistinmontreal.com/",
        },
        sliderImages: [
          // "images/projects/project-1.jpg",
          // "images/projects/project-2.png",
        ],
      },
      thumbImage: "images/projects/project-2.png", 
      categories: [filters.WORDPRESS],
    },

    {
      title: "Detailed Project 3",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client is a makeup artist",
        client: "Makeup by Design",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Cosmetic Industry",
        // date: "July 16, 2019",
        url: {
          name: "makeupbydesign.ca",
          link: "https://makeupbydesign.ca",
        },
        sliderImages: [
          // "images/projects/project-1.jpg",
          // "images/projects/project-2.png",
        ],
      },
      thumbImage: "images/projects/project-3.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 4",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client is a dentist in Toronto and Brampton",
        client: "Shoreham Dental",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "shorehamdental.ca",
          link: "https://shorehamdental.ca",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },
      thumbImage: "images/projects/project-4.jpg", 
      categories: [filters.WORDPRESS],
    },
   
    {
      title: "Detailed Project 5",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website (as well as two other sites for his other locations) family dentist dentist. This is the Lindsay office.",
        client: "Horizon Family Dental",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "lindsay.horizonfamilydentistry.com",
          link: "https://lindsay.horizonfamilydentistry.com",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-5.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 6",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client operates as a dentist in Toronto",
        client: "THe Vein Institute of Toronto",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "theveininstitute.com",
          link: "https://theveininstitute.com",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-6.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 7",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client operates as a dentist in Toronto",
        client: "Eringate Dental Clinic",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator, Figma",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "eringatedental.com",
          link: "https://eringatedental.com",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-7.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 8",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I completely designed and developed this website from scratch. This client is a Prosthodontist that specializes in cosmetic, restorative and implant dentistry. ",
        client: "RB Prosthodontics",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry, Prosthdontics",
        // date: "July 16, 2019",
        url: {
          name: "rbprosthodontics.com",
          link: "https://www.rbprosthodontics.com",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-8.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 9",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client operates as a dentist in Kingston, ON",
        client: "Anderson Dentistry",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "andersondentistry.ca",
          link: "https://andersondentistry.ca",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-9.png", 
      categories: [filters.WORDPRESS],
    },
   
    {
      title: "Detailed Project 10",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client operates as a dentist in Richmond Hill, ON",
        client: "Regatta Dental",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "regattadentalcentre.ca",
          link: "https://regattadentalcentre.ca",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-10.png", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 11",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Completely re-designed and developed this website for whom the client operates as a dentist in Brantford, ON",
        client: "Lynden Park Dental",
        agency: "Identity Namebrands Inc.",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dentistry",
        // date: "July 16, 2019",
        url: {
          name: "lyndenparkdental.com",
          link: "https://lyndenparkdental.com",
        },

        // sliderImages: [
        //   // "images/projects/project-2.png",
        //   // // "images/projects/project-5.jpg",
        // ],
      },
      thumbImage: "images/projects/project-11.jpg", 
      categories: [filters.WORDPRESS],
    },
    {
      title: "Detailed Project 12",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I completely redesigned and developed this website. This client specializes in unique custom design of dental offices including equipment sales and support",
        client: "Dentist Concierge",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Dental Office Design and Construction",
        // date: "July 16, 2019",
        url: {
          name: "dentistconcierge.ca",
          link: "https://dentistconcierge.ca",
        },
        sliderImages: [
          // "images/projects/project-1.jpg",
          // "images/projects/project-2.png",
        ],
      },
      thumbImage: "images/projects/project-16.png", 
      categories: [filters.WORDPRESS],
    }, 
    {
      title: "Detailed Project 13",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I completely designed and developed this website from scratch. This client is a French teacher who primarily teaches french onsite to employees in office buildings.",
        client: "French Onsite",
        technologies: "Wordpress, HTML, CSS, PHP, Adobe Photoshop, Adobe Illustrator",
        industry: "Language School",
        // date: "July 16, 2019",
        url: {
          name: "onsitelanguages.com",
          link: "https://onsitelanguages.com",
        },
        sliderImages: [
          // "images/projects/project-1.jpg",
          // "images/projects/project-2.png",
        ],
      },
      thumbImage: "images/projects/project-15.png", 
      categories: [filters.WORDPRESS],
    },   
    {
      title: "React Project 2",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Designed and developed a Pokedex App with React. API requests are made to the Poke API. 151 Pokemon character buttons are displayed on the home page, all of which are linked to their own stats page",
        // client: "Pokedex",
        technologies: "React, React-Router, Styled Components, Components,  UseState, API, Bootstrap, HTML, CSS",
        industry: "Gaming",
        // date: "July 16, 2019",
        url: {
          name: "solomon-pokedex.netlify.app",
          link: "https://solomon-pokedex.netlify.app",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/react-project-2.jpg",

      categories: [filters.REACT],
    },
    {
      title: "React Project 3",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a potential re-design of my current online portfolio. It would be a single page application",
        // client: "Pokedex",
        technologies: "React, Vite JS, Components, UseState, Tailwind CSS, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Web Development",
        // date: "July 16, 2019",
        url: {
          name: "portfolio.sobadiaportfolio.com",
          link: "https://portfolio.sobadiaportfolio.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/react-project-3.png",

      categories: [filters.REACT],
    },
    {
      title: "React Project 4",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a mock landing page for a Nike tennis shoe (educational purposes). I used primarily React and the Framer motion library for the animated effect",
        // client: "Pokedex",
        technologies: "React, Components, Framer Motion, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Athletic Footwear",
        // date: "July 16, 2019",
        url: {
          name: "nikelandingpage.sobadiaportfolio.com/",
          link: "https://nikelandingpage.sobadiaportfolio.com/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/nike-landing-page.png",

      categories: [filters.REACT],
    },
    {
      title: "React Project 5",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a mock landing page for a fitness gym (educational purposes).",
        // client: "Pokedex",
        technologies: "React, Components, CSS BEM Model, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Fitness",
        // date: "July 16, 2019",
        url: {
          name: "thegym.sobadiaportfolio.com",
          link: "https://thegym.sobadiaportfolio.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/thegym.png",

      categories: [filters.REACT],
    },
    {
      title: "React Project 6",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a mock landing page for a modern bike store (educational purposes).",
        // client: "Pokedex",
        technologies: "Next 13, React, Components, Hooks, useEffect, Framer Motion, Tailwind CSS, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Bicycle",
        // date: "July 16, 2019",
        url: {
          name: "bikysworld.sobadiaportfolio.com/",
          link: "https://bikysworld.sobadiaportfolio.com/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/react-bikysworld.png",

      categories: [filters.REACT],
    },
    {
      title: "SVG Animation Project 2",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Project I created as an entrance test involving animating an SVG illustration (M & H Logo) using GSAP (Javascript Framework for Amimation) Javascript, HTML, and CSS",
        client: "M & H Marketing Agency",
        technologies: "HTML, CSS3, Javascript, GSAP,  SVG",
        industry: "SVG Animation",
        // date: "July 16, 2019",
        url: {
          name: "https://sobadiaportfolio.com/mh/",
          link: "https://sobadiaportfolio.com/mh/",
        },



        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/svg-project-2.png",

      categories: [filters.ANIMATION],
    },
    {
      title: "SVG Animation Project 3",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project involving animating an SVG illustration (Bee) by scrolling using GSAP (Javascript Framework for Amimation) and ScrollMagic(Javascript Library used with the GSAP Frame work to create special scroll effects), Javascript, HTML, and CSS",
        client: "Personal Project",
        technologies: "HTML, CSS3, Javascript, GSAP,  SVG",
        industry: "SVG Animation",
        // date: "July 16, 2019",
        url: {
          name: "https://sobadiaportfolio.com/bee/",
          link: "https://sobadiaportfolio.com/bee/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/svg-project-3.png",

      categories: [filters.ANIMATION],
    },
    {
      title: "Animation Project 4",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a mock landing page for a modern bike store (educational purposes).",
        // client: "Pokedex",
        technologies: "Next 13, React, Components, Hooks, useEffect, Framer Motion, Tailwind CSS, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Bicycle",
        // date: "July 16, 2019",
        url: {
          name: "bikysworld.sobadiaportfolio.com/",
          link: "https://bikysworld.sobadiaportfolio.com/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/react-bikysworld.png",

      categories: [filters.ANIMATION],
    },
    {
      title: "Animation Project 5",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Personal project: This is a mock landing page for a Nike tennis shoe (educational purposes). I used primarily React and the Framer motion library for the animated effect",
        // client: "Pokedex",
        technologies: "React, Components, Framer Motion, CSS Flexbox, CSS Flexgrid, HTML, CSS",
        industry: "Athletic Footwear",
        // date: "July 16, 2019",
        url: {
          name: "nikelandingpage.sobadiaportfolio.com/",
          link: "https://nikelandingpage.sobadiaportfolio.com/",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/nike-landing-page.png",

      categories: [filters.ANIMATION],
    },

    {
      title: "Graphic Design Project 2",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Created 1/4 to full-page black/white and full colour process ads in a range of professions using primarily Illustrator and Photoshop",
        // client: "Yellow Pages Group",
        agency:  "Yellow Pages Group",
        technologies: "Adobe Photoshop, Adobe Illustrator",
        industry: "Publishing",
        date: "1999 - 2009",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-2.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    
    {
      title: "Graphic Design Project 3",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Designed logos, posters, business cards and updated the website for promotion of this well-known French social club in Toronto",
        client: "Francophonique",
        technologies: "Adobe Illustrator, Adobe Photoshop, Adobe Indesign",
        industry: "French Social Club",
        date: "2002-2007",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-3.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    {
      title: "Graphic Design Project 4",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Created the corporate identity for this logistics company which included logo, businesscard, letterhead and presentation folder.",
        client: "Greenline Logistics",
        technologies: "Adobe Illustrator, Adobe Photoshop, Adobe Indesign",
        industry: "Transportation Logistics",
        // date: "July 16, 2019",
        url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-4.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    {
      title: "Graphic Design Project 5",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Designed, photographed, and produced film for brochures, company folders, header cards, ticket channels, ceiling cards, sell sheets, box designs, pressure-sensitive labels, and flexo packaging. Major clients included Shopper’s Drug Mart, Johnvince Foods, Blockbuster, and Planters.",
        client: "Rockprint Inc (currently named Creative Rock Design Studio)",
        technologies: "Adobe Illustrator, Adobe Photoshop, QuarkXpress",
        industry: "Package Design",
        date: "1995-1998",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-5.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    {
      title: "Graphic Design Project 6",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "T-star Home Improvements (Full color flyer) - JK Reno (Full-color businesscard) - Francine Haemen (Full-color business card)",
        client: "Varioius Freelance Assignments",
        technologies: "Adobe Illustrator, Adobe Photoshoop, Adobe Indesign",
        industry: "Corporate Design",
        // date: "July 16, 2019",
        // url: {
        //   name: "www.example.com",
        //   link: "https://www.example.com",
        // },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-6.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    {
      title: "Graphic Design Project 7",
      type: types.DOCUMENT,
      document: {
        projectInfo:
        "Signage - car magnet",
        client: "JK Reno Construction",
        technologies: "Adobe Illustrator, Adobe Photoshop",
        industry: "Lanscaping and Renovation",
        // date: "July 16, 2019",
        url: {
          name: "jk-reno.com",
          link: "https://jk-reno.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-7.jpg",

      categories: [filters.GRAPHICDESIGN],
    },
    {
      title: "Graphic Design Project 8",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
        client: "Ruby Clinton",
        technologies: "iOS, HTML5, CSS3, PHP, Java",
        industry: "Art & Design",
        date: "July 16, 2019",
        url: {
          name: "www.example.com",
          link: "https://www.example.com",
        },

        sliderImages: [
          // "images/projects/project-2.png",
          // // "images/projects/project-5.jpg",
        ],
      },


      thumbImage: "images/projects/graphic-design-project-8.jpg",

      categories: [filters.GRAPHICDESIGN],
    },


  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className="section">
        <div className={"container"}>
          {/* Heading */}
          <p className="text-center mb-2 wow fadeInUp">
            <span className="bg-primary text-dark px-2">Portfolio</span>
          </p>
          <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
            Some of my most recent projects
          </h2>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs fw-600 justify-content-start justify-content-md-center border-bottom-0 mb-5 wow fadeInUp"
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " + (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box">
                      <div className="portfolio-img">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 ">
                            {" "}
                          </button>
                          <div className="portfolio-overlay-details">
                            <p className="text-primary text-8">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white text-5">
                              {project?.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
